<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings" :readonly="$authorised('con/checkout/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/checkout/access', 'read')">

			<app-input-text :toggle="model.checkout.limit.games.enabled" v-on:toggle="model.checkout.limit.games.enabled = !model.checkout.limit.games.enabled" label="Active Loan Limit" v-model="model.checkout.limit.games.amount" :validation="$v.model.checkout.limit.games.amount" />
			<app-input-text :toggle="model.checkout.limit.hours.enabled" v-on:toggle="model.checkout.limit.hours.enabled = !model.checkout.limit.hours.enabled" label="Hours Due Limit" v-model="model.checkout.limit.hours.amount" :validation="$v.model.checkout.limit.hours.amount" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			settings: true,
			readonly: this.$authorised('con/checkout/access', 'read'),
			model: {
				checkout: {
					limit: {
						games: {
							enabled: false,
							amount: 0
						},
						hours: {
							enabled: false,
							amount: 0
						}
					},
					logging: {
						enabled: false
					}
				}
			}
		}

	},

	validations: {
		model: {
			checkout: {
				limit: {
					games: {},
					hours: {}
				},
				logging: {}
			}
		}
	}

}

</script>

<style scoped>

</style>